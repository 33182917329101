// @font-face {
//   font-family: 'ABC-Social';
//   src: url('../assets/ABC-Social.eot');
//   src: url('../assets/ABC-Social.eot?#iefix') format('embedded-opentype'),
//       url('../assets/ABC-Social.woff2') format('woff2'),
//       url('../assets/ABC-Social.woff') format('woff'),
//       url('../assets/ABC-Social.ttf') format('truetype'),
//       url('../assets/ABC-Social.svg#ABC-Social') format('svg');
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }

html,
body {
  font-family:  sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-weight: bold;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.2s ease-out;
  @include hover {
    text-decoration: none;
    color: inherit;
  }
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

@include mobile {
}
