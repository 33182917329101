

*::selection {
  background: rgba(0, 0, 0, 0.99);
  color: #FFF;
}
*::-moz-selection {
  background: rgba(0, 0, 0, 0.99);
  color: #FFF;
}

*::-webkit-scrollbar {
  width: 0px;
  display: none;
}
*::-webkit-scrollbar-track {
  background-color: #FFF;
  display: none;
}

*::-webkit-scrollbar-thumb {
  background-color: #000;
  border: none;
}


html,
body {
  width: 100%;
  height: 100%;
}

.header{

  &__logo-wrapper{
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translate(-50%);
    z-index: 100;
    height: 30px;
    width: auto;
    padding: 20px 30px;
    background-color: #FFF;
    border-radius: 0 0 20px 20px;
  }

  &__logo{
    height: 100%;
    width: auto;
    display: block;
  }

  &__info{
    padding: 10px 10px 10px 15px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    background-color: #FFF;
    border-radius: 0 0 0 20px;
  }

  &__back{
    padding: 10px 15px 10px 10px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #FFF;
    border-radius: 0 0 20px 0;
  }

}

@include mobile{
  .header{
    &__logo-wrapper{
      width: 90px;
    }
  }
}

.footer{
  &__wrapper{
    width: 100%;
    padding: 10px 0 30px 0;
    text-align: center;
    position: relative;
    z-index: 10;
  }

  &__text{
    padding: 10px 30px;
    background-color: #FFF;
    border-radius: 20px;
    display: inline;
  }

}

.loading-wrapper{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 30px;
  background-color: #FFF;
  border-radius: 30px;
  z-index: 100;
}

.home{

  &__canvas-wrapper{
    width: 110%;
    height: 110vh;
    position: fixed;
    top: -5vh;
    left: -5%;
  }

  &__product-grid{
    width: calc(100% - 60px);
    margin-top: 95vh;
    padding: 0 30px 50px 30px;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    position: relative;
    z-index: 2;
  }

  &__grid-item{
    margin: 0 20px 30px 20px;
    width: calc(33.33% - 50px);
  }

  &__grid-image{
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 5px;
    border-radius: 20px;
  }

  &__grid-title{
    width: calc(100% - 20px);
    text-align: center;
    padding: 10px;
    background-color: #FFF;
    border-radius: 20px;
    // font-weight: bold;
  }
}
  
@include mobile{

  .home{

    &__product-grid{
      width: calc(100% - 40px);
      padding: 0 20px 50px 20px;
    }

    &__grid-item{
      margin: 0 0 30px 0;
      width: 100%;
    }
  }
}