.product{
    &__main-image-wrapper{
        width: 100%;
        height: auto;
    }

    &__main-image{
        width: 100%;
        display: block;
    }
}


.buy-button__wrapper {
  width: calc(100% - 40px);
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  text-align: center;
  padding: 20px;

  .shopify-buy__btn {
    padding: 5px 20px;
    border: 0.5px solid #000;
    border-radius: 50px;
    margin: 0;
  }
}

.shopify-buy{

  &__btn{
    padding: 5px 20px;
    display: inline-block;
    border: 0.5px solid #000;
    border-radius: 50px;
    margin: 0;
    font-weight: bold;
  }

  &__btn-wrapper{
    margin-bottom: 10px;
  }

  &__product__title,
  &__product__actual-price{
    display: block;
    margin-bottom: 10px;
  }

  &__product-description{
    p{
      margin-bottom: 10px;
    }
  }
}

.visuallyhidden {
  display: none;
}

// .shopify-buy__select-icon{
//   display: none;
// }
